import React from 'react';
import Image from 'gatsby-image';
import Layout from '../../components/Layout';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Favicon from '../../images/favicon.ico';
import CountUp from 'react-countup';
import { Link } from 'gatsby';

const TYPE_CLE = [
  {
    name: 'Clé plate',

    securityLevel: 'Faible',
    reproduction: 'Facile',
    uses: 'Principalement pour verrouiller des portes intérieures, des boîtes aux lettres, des cadenas légers, etc.',
  },
  {
    name: 'Clé à bille',

    securityLevel: 'Modéré',
    reproduction: 'Difficile',
    uses: 'verrouiller des portes extérieures résidentielles et commerciales, des serrures de voiture, des systèmes de sécurité, etc.',
  },
  {
    name: 'Passe partout',

    securityLevel: 'Bon',
    reproduction: 'Facile',
    uses: 'elle est pratique pour les gestionnaires de bâtiments, mais cela peut compromettre la sécurité si elle est perdue ou volée',
  },
  {
    name: 'Clé technique',

    securityLevel: 'Bon',
    reproduction: 'Difficile',
    uses: 'Utilisée principalement par des professionnels qualifiés pour accéder à des équipements spécifiques ou à des zones restreintes, comme des panneaux électriques, des systèmes de sécurité avancés, etc.',
  },
  {
    name: 'Clé de haute sécurité',
    securityLevel: 'Fort',
    reproduction: 'Très difficile',
    uses: 'sécuriser des biens de grande valeur, des bâtiments commerciaux, des installations gouvernementales, des systèmes de sécurité avancés, etc.',
  },
  {
    name: 'Clé à pompe',
    securityLevel: 'Élevé',
    reproduction: 'Difficile',
    uses: 'verrouiller des portes et des systèmes de sécurité, notamment dans les domaines de la sécurité automobile et industrielle.',
  },
  {
    name: 'Clé à chiffres',
    securityLevel: 'Modéré',
    reproduction: '//',
    uses: 'Principalement pour les coffres-forts, les casiers, les systèmes de verrouillage à combinaison, etc.',
  },

  {
    name: 'Clé à points',

    securityLevel: 'Modéré',
    reproduction: 'Difficile',
    uses: 'Principalement pour les serrures à goupilles, offrant un niveau de sécurité supérieur à celui des clés plates.',
  },
];
function DoubleCle({ data }) {
  const { SuperSerrurierVan, KeyTypes, SerrurierPicture } = data;
  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={Favicon} />
        <meta charSet="utf-8" />
        <title>
          Service de duplication de clés | Service rapide et professionnel
        </title>
        <meta
          name="description"
          content="Service professionnelle et rapide de duplication de clés résidentielles et commerciales. Notre équipe qualifiée est disponible 24h/24 pour répondre à vos besoins de double de clé. Obtenez un devis gratuit en un clic ou contactez-nous au +4179 130 47 68 pour bénéficier de notre service fiable"
        />
      </Helmet>
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <main className="flex justify-between gap-4 lg:gap-8 mt-16 sm:mt-24 flex-col lg:flex-row">
          <div className="w-full lg:w-1/2">
            <h1 className="text-textcolor text-2xl tracking-tight font-extrabold sm:leading-none lg:text-4xl xl:text-5xl">
              Service professionnel et rapide de duplication de clés
            </h1>
            <div className="w-full lg:hidden">
              <Image
                fluid={SuperSerrurierVan.childImageSharp.fluid}
                alt="Voiture de service utulisé pour les intervention d'ouverture de porte"
              />
            </div>
            <p className="mt-4 text-lg text-gray-600">
              Lorsque vous avez besoin de faire dupliquer une clé, faites
              confiance à notre équipe expérimentée pour un service de double de
              clé efficace et professionnel.
              <br />
              Que ce soit pour une clé perdue, une clé supplémentaire ou tout
              autre besoin de duplication de clé, notre équipe est prête à vous
              aider rapidement. Nous travaillons avec précision et utilisons des
              techniques de pointe pour vous fournir des copies de clés de haute
              qualité.
            </p>
            <a
              id="call-button"
              href="tel:0791304768"
              className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
            >
              <span className="px-3 py-2 text-white text-lg  lg:text-xl xl:text-2xl  leading-5  tracking-wide bg-primarycolor rounded-full">
                Appelez-nous
              </span>
              <span className="ml-3 text-lg  lg:text-xl xl:text-2xl font-semibold leading-5  ">
                079 130 47 68
              </span>
              <svg
                className="ml-2 w-6 h-6 "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <div className="w-full hidden lg:block lg:w-1/2">
            <Image
              fluid={SuperSerrurierVan.childImageSharp.fluid}
              alt="Voiture de service utulisé pour les intervention d'ouverture de porte"
            />
          </div>
        </main>
        <div className="w-screen absolute left-0 mt-24 py-8 lg:py-14 h-fit bg-primarycolor">
          <dl className="text-center sm:max-w-7xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                Clients par an
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp start={0} end={3000} duration={2.75} prefix="+ " />
              </dd>
            </div>{' '}
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                Temps d'intervention
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp
                  end={20}
                  start={60}
                  duration={2.75}
                  suffix=" Minutes"
                />
              </dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                De satisfaction
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp start={0} end={98} duration={2.75} suffix="%" />
              </dd>
            </div>
          </dl>
        </div>
        <div className="mt-128 lg:mt-96 rounded-md relative lg:grid overflow-x-scroll lg:overflow-x-hidden lg:items-center">
          <h2 className="text-primarycolor text-center text-2xl tracking-tight font-extrabold sm:leading-none lg:text-4xl xl:text-5xl">
            Les différents types de clés
          </h2>
          <table id="prices-table" className="table-auto mt-8">
            <thead>
              <tr>
                <th className="text-white w-52 border-2 border-gray-900 bg-primarycolor-light py-2">
                  Modele de clé
                </th>

                <th className="text-white w-52 border-2 border-gray-900 bg-primarycolor-light py-2">
                  Niveau de sécurité
                </th>
                <th className="text-white w-52 border-2 border-gray-900 bg-primarycolor-light py-2">
                  Reproduction
                </th>
                <th className="text-white border-2 border-gray-900 bg-primarycolor-light py-2">
                  Utilisation
                </th>
              </tr>
            </thead>
            <tbody>
              {TYPE_CLE.map((e) => {
                return (
                  <tr>
                    <td className="text-textcolor text-center border-2 border-gray-900 py-2">
                      {e.name}
                    </td>

                    <td className="text-textcolor border-2 text-center border-gray-900  py-2">
                      {e.securityLevel}
                    </td>
                    <td className="text-textcolor border-2 text-center border-gray-900  py-2">
                      {e.reproduction}
                    </td>
                    <td className="text-textcolor border-2 text-center border-gray-900  py-2">
                      {e.uses}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="mt-12 lg:mt-32 rounded-md relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <Image
              fluid={KeyTypes.childImageSharp.fluid}
              alt="Les types de clés"
              className={'hidden lg:block'}
            />
          </div>{' '}
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className="inline text-primarycolor">
                Pourquoi faire un double de vos clés ?
              </h2>
              <Image
                fluid={KeyTypes.childImageSharp.fluid}
                alt="Les types de clés"
                className={'my-8 lg:hidden'}
              />
            </div>
            <ul className="mt-4 lg:mt-8 list-disc space-y-2 text-lg leading-6 font-medium text-gray-600">
              Il y a plusieurs raisons pour lesquelles vous pourriez vouloir
              faire un double de vos clés :
              <li className="text-lg leading-6 font-medium text-gray-600">
                <b>Situation d'urgence : </b> En cas d'urgence, comme un
                incendie ou un problème médical, les secours peuvent avoir
                besoin d'accéder rapidement à votre domicile. Un double de clé
                peut faciliter leur entrée.
              </li>
              <li className="text-lg leading-6 font-medium text-gray-600">
                <b>Éviter les frais de serrurier : </b> Si vous vous retrouvez
                enfermé hors de chez vous et que vous n'avez pas de double de
                clé, vous devrez faire appel à un serrurier, ce qui peut être
                coûteux. Posséder un double de clé peut vous faire économiser du
                temps et de l'argent dans de telles situations.
              </li>
              <li className="text-lg leading-6 font-medium text-gray-600">
                <b>Prêt ou partage : </b> Vous pourriez avoir besoin de prêter
                temporairement vos clés à un ami, un voisin ou un membre de la
                famille. Avoir un double de clé permet de partager l'accès sans
                avoir à remettre l'original. porte.
              </li>
            </ul>

            <div className="mt-8 sm:flex">
              <div className="block rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light"
                >
                  Appelez-nous
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 lg:mt-32 rounded-md relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className="inline text-primarycolor">
                Tarifs et Prix pour la duplication de clés
              </h2>
              <Image
                fluid={SerrurierPicture.childImageSharp.fluid}
                alt="dupliqué une clé a geneve"
                className={'my-4 lg:hidden rounded-lg'}
              />
            </div>
            <div className="mt-4 lg:mt-8 list-disc space-y-4">
              <p className="text-lg leading-6 font-medium text-gray-600">
                En ce qui concerne la tarification, les frais associés à la
                duplication de clés par un serrurier agréé sont influencés par
                divers éléments, dont la durée de l'intervention, la distance à
                parcourir, Type de clé, Marque et qualité de clé, Complexité de
                la duplication, ainsi que la quantité de clés souhaitée.
              </p>
            </div>

            <div className="mt-8 sm:flex">
              <div className="block rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light"
                >
                  Appelez-nous
                </a>
              </div>
            </div>
          </div>
          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <Image
              fluid={SerrurierPicture.childImageSharp.fluid}
              alt="dupliqué une clé a geneve"
              className={'hidden lg:block rounded-lg'}
            />
          </div>{' '}
        </div>
        <div className="mt-12 lg:mt-32">
          <h2 className="text-primarycolor text-2xl font-extrabold text-center tracking-tight sm:text-5xl">
            Nos Zones d’interventions
          </h2>
          <div className="pt-12 grid grid-cols-2 content-center gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-5">
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/valais"
                className="zone-area duration-150 text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.valais.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Valais"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Valais
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/serrurier-geneve"
                className="zone-area duration-150 flex flex-col items-center text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.geneve.childImageSharp.fixed}
                  className="h-32 max-w-32 max-h-32 mx-auto conton"
                  alt="Genève"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Genève
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/neuchatel"
                className="zone-area duration-150 flex flex-col items-center text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.neuchatel.childImageSharp.fixed}
                  className="h-32 max-w-32  max-h-32 conton"
                  alt="Neuchatel"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Neuchatel
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8 text-gray-600 hover:text-primarycolor">
              <Link
                to="/vaud"
                className="zone-area flex flex-col items-center duration-150"
              >
                <Image
                  fixed={data.vaud.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Vaud"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Vaud
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-14 text-gray-600 hover:text-primarycolor">
              <Link
                to="/fribourg"
                className="zone-area flex flex-col items-center duration-150"
              >
                <Image
                  fixed={data.fribourg.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Fribourg"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Fribourg
                </h3>
              </Link>
            </div>
          </div>
          <div className="mx-auto flex justify-center pb-12">
            <a
              id="call-button"
              href="tel:0791304768"
              className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
            >
              <span className="px-3 py-2 text-white text-lg  lg:text-xl xl:text-2xl  leading-5  tracking-wide bg-primarycolor rounded-full">
                Appelez-nous
              </span>
              <span className="  ml-3 text-lg  lg:text-xl xl:text-2xl font-semibold leading-5  ">
                079 130 47 68
              </span>
              <svg
                className="ml-2 w-6 h-6 "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default DoubleCle;

export const query = graphql`
  query double_cle_page {
    SuperSerrurierVan: file(relativePath: { eq: "SuperSerrurierVan.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    SerrurierPicture: file(relativePath: { eq: "cle-geneve.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    KeyTypes: file(relativePath: { eq: "key-types.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    fribourg: file(relativePath: { eq: "Fribourg.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    geneve: file(relativePath: { eq: "Geneve.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    neuchatel: file(relativePath: { eq: "neuchatel.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    valais: file(relativePath: { eq: "Valais.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    vaud: file(relativePath: { eq: "Vaud.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
